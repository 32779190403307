



























import { Component, Prop, Vue } from "vue-property-decorator";
import Button, { ButtonSizes } from "@/components/Button.vue";
import colors from "@/styles/colors";

export interface JobResultItem {
  company: string;
  jobTitle: string;
  jobReqs: string;
  link: string;
}

@Component({
  name: "JobResults",
  components: {
    Button,
  },
})
export default class JobResults extends Vue {
  @Prop() jobResults!: JobResultItem[];

  private buttonSize: ButtonSizes = ButtonSizes.medium;
  private buttonColor: string = colors.get().accent;

  private goToLink(value: string) {
    window.open(value);
  }
}
