




















































import { Component, Vue } from "vue-property-decorator";
import PageCover from "@/components/PageCover.vue";
import Divider from "@/components/Divider.vue";
import Subtitle from "@/components/Subtitle.vue";
import Form from "@/components/Form.vue";
import { InputObject } from "@/components/FormInput.vue";
import Picture, { ImageObject, ImageSize } from "@/components/Picture.vue";
import Button, { ButtonSizes } from "@/components/Button.vue";
import colors from "@/styles/colors";
import axios from 'axios';
import TextArea from "@/components/TextArea.vue";
import Grid, { GridCellObject } from "@/components/Grid.vue";
import JobResults, { JobResultItem } from "@/components/JobResults.vue";
import { ResourceModel } from '@/axios/modelInterfaces';

@Component({
  name: "IndustryResources",
  components: {
    PageCover,
    Divider,
    Subtitle,
    Form,
    Picture,
    Button,
    TextArea,
    Grid,
    JobResults,
  },
})
export default class IndustryResources extends Vue {
  private jobHelpGrid: GridCellObject[] = [
  ];

  private projectHelpGrid: GridCellObject[] = [
  ];

  private jobType: "electrical" | "software" | "computer" | "" = "";

  private specifyJobType(
    jobType: "electrical" | "software" | "computer" | ""
  ): void {
    this.jobType = jobType;
  }

  private jobResults: JobResultItem[] = [
  ];

  private async created() {
    this.jobHelpGrid = await axios.get(`/resources/byCategory`, {params: {category: "Industry"}}).then((result) => {
        const resourcesRawData: ResourceModel[] = result.data.data;
        const resourcesProcessed: GridCellObject[] = resourcesRawData.map(resource => ({ title: resource.title, description: resource.description, image: { alt: "Industry Resource", path: resource.image }, button: `Visit ${resource.title}`, buttonLink: resource.link }));
        return resourcesProcessed;
    })

    this.jobHelpGrid = await axios.get(`/resources/byCategory`, {params: {category: "Projects"}}).then((result) => {
        const resourcesRawData: ResourceModel[] = result.data.data;
        const resourcesProcessed: GridCellObject[] = resourcesRawData.map(resource => ({ title: resource.title, description: resource.description, image: { alt: "Project Resource", path: resource.image }, button: `Visit ${resource.title}`, buttonLink: resource.link }));
        return resourcesProcessed;
    })
  }

  private buttonColor: string = colors.get().input;
  private buttonSize: ButtonSizes = ButtonSizes.big;
}
